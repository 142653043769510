import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import {
	SIGNIN_USER,
	SIGNOUT_USER,
	// SIGNUP_USER,
	SIGNIN_USER_SUCCESS,
	SIGNIN_USER_FAILED,
	SIGNOUT_USER_SUCCESS,
	SIGNOUT_USER_FAILED,
	FORGOT_PASSWORD,
	BACK_TO_LOGIN,
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_FAILED,
	CHANGE_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAILED,
    OTP_USER_SUCCESS,
    OTP_USER_FAILED
} from "constants/ActionTypes"
import {
	signInUserWithEmailPasswordRequest,
	changePasswordApi,
	forgotPasswordApi,
	signOutUserApi,
} from "../../appRedux/api/Auth"
// import {userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../../appRedux/actions/Auth";
// import {showMessage, hideLoader} from "../../appRedux/actions/Common";

// function here

// function* createUserWithEmailPassword({payload}) {
//     const {email, password} = payload;
//     try {
//         const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
//         if (signUpUser.message) {
//             yield put(showMessage(signUpUser.message));
//         } else {
//             localStorage.setItem('user_id', signUpUser.user.uid);
//             yield put({type: 'SIGNIN_USER_SUCCESS', payload: signUpUser.user.uid});
//         }
//     } catch (error) {
//         yield put(showMessage(error));
//     }
// }

function* signIn({ payload }) {
	try {
		const signInUser = yield call(signInUserWithEmailPasswordRequest, payload)
        let auth = {
            authToken: signInUser.data.authToken,
            deviceId: signInUser.data.authDevice,
            userId: signInUser.data.memberId,
            merchantId: signInUser.data.merchantId,
            firstName: signInUser.data.firstName,
            merchantName: signInUser.data.merchantName,
            userPic: signInUser.data.userPic,
        }

        localStorage.setItem("u", signInUser.data.memberId)
        localStorage.setItem("f", signInUser.data.firstName)
        localStorage.setItem("mt", signInUser.data.merchantId)
        localStorage.setItem("a", signInUser.data.authToken)
        localStorage.setItem("d", signInUser.data.authDevice)
        localStorage.setItem("mtn", signInUser.data.merchantName)
        localStorage.setItem("upic", signInUser.data.userPic)

		if (signInUser.data.abstractResponse.responseStatus === "AUTH000") {
			yield put({ type: SIGNIN_USER_SUCCESS, payload: auth })
		} else if (signInUser.data.abstractResponse.responseStatus === "AUTH018") {
			localStorage.setItem("memberId", signInUser.data.memberId)
            yield put({ type: OTP_USER_SUCCESS, payload: auth })
        }  else if(signInUser.data.abstractResponse.responseStatus  === "AUTH021") {
			yield put({
				type: OTP_USER_FAILED,
				payload: signInUser.data.abstractResponse.responseMessage,
			})
		} else if (signInUser.data.abstractResponse.responseStatus === "AUTH022") {
            yield put({
				type: OTP_USER_FAILED,
				payload: signInUser.data.abstractResponse.responseMessage,
			})
        } else {
			yield put({
				type: SIGNIN_USER_FAILED,
				payload: signInUser.data,
			})
		}
	} catch (error) {
		if (error.response.status === 500) {
			yield put({
				type: SIGNIN_USER_FAILED,
				payload:
					error.response.data +
					` Please Contact the Administrator`,
			})
		} else if (error.response !== undefined) {
			yield put({
				type: SIGNIN_USER_FAILED,
				payload: error.data,
			})
		} else {
			yield put({
				type: SIGNIN_USER_FAILED,
				payload: "Please Check Your Internet Conection",
			})
		}
	}
}

function* signOut({payload}) {
	let authToken2 = localStorage.getItem("a")
		let payload2 = {
            authToken: authToken2,
			userId: localStorage.getItem("u")
        }
		
            let signOutUser = yield call(signOutUserApi, payload2);
            if (signOutUser.data.abstractResponse.responseStatus === 'AUTH007') {
                yield put({type: SIGNOUT_USER_SUCCESS, payload: signOutUser.data});
            } else {
                yield put({type: SIGNOUT_USER_FAILED, payload: signOutUser.data.abstractResponse.responseMessage});
            }
}

// function* signOut({ payload }) {
// 	try {
// 		const signOutUser = yield call(signOutUserApi, payload)
// 		//     let auth = {
//         //     authToken: signInUser.data.authToken,
//         //     deviceId: signInUser.data.authDevice,
//         //     userId: signInUser.data.memberId,
//         //     merchantId: signInUser.data.merchantId,
//         //     firstName: signInUser.data.firstName,
//         //     merchantName: signInUser.data.merchantName,
//         //     userPic: signInUser.data.userPic,
//         // }

//         // localStorage.setItem("u", signInUser.data.memberId)
//         // localStorage.setItem("f", signInUser.data.firstName)
//         // localStorage.setItem("mt", signInUser.data.merchantId)
//         // localStorage.setItem("a", signInUser.data.authToken)
//         // localStorage.setItem("d", signInUser.data.authDevice)
//         // localStorage.setItem("mtn", signInUser.data.merchantName)
//         // localStorage.setItem("upic", signInUser.data.userPic)

		

// 		if (signOutUser.data.abstractResponse.responseStatus === "AUTH007") {
// 			yield put({ type: SIGNOUT_USER_SUCCESS, payload: signOutUser.data})
// 		} else {
// 			yield put({
// 				type: SIGNOUT_USER_FAILED,
// 				payload: signOutUser.data.abstractResponse.responseMessage,
// 			})
// 		}
// 	} catch (error) {
// 		if (error.response.status === 500) {
// 			yield put({
// 				type: SIGNOUT_USER_FAILED,
// 				payload:
// 					error.response.authenticationTokens.abstractResponse.responseMessage +
// 					` Please Contact the Administrator`,
// 			})
// 		} else if (error.response !== undefined) {
// 			yield put({
// 				type: SIGNOUT_USER_FAILED,
// 				payload: error.data.abstractResponse.responseMessage,
// 			})
// 		} else {
// 			yield put({
// 				type: SIGNOUT_USER_FAILED,
// 				payload: "Please Check Your Internet Conection",
// 			})
// 		}
// 	}
// }

function* postForgotPassword({ payload }) {
	try {
		const forgotPasswordData = yield call(forgotPasswordApi, payload)
		if (forgotPasswordData.data.abstractResponse.responseStatus === "AUTH000") {
			let responseData = forgotPasswordData.data.rule
			yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: responseData })
		} else {
			yield put({
				type: FORGOT_PASSWORD_FAILED,
				payload: forgotPasswordData.data.abstractResponse.responseMessage,
			})
		}
	} catch (error) {
		yield put({
			type: FORGOT_PASSWORD_FAILED,
			payload: error.data.abstractResponse.responseMessage,
		})
	}
}

function* postChangePassword({ payload }) {
	try {
		const changePasswordData = yield call(changePasswordApi, payload)
		if (changePasswordData.data.abstractResponse.responseStatus === "AUTH000") {
			let responseData = changePasswordData.data
			yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: responseData })
		} else {
			yield put({
				type: CHANGE_PASSWORD_FAILED,
				payload: changePasswordData.data.abstractResponse.responseMessage,
			})
		}
	} catch (error) {
		yield put({
			type: CHANGE_PASSWORD_FAILED,
			payload: error.data.abstractResponse.responseMessage,
		})
	}
}

// function* signOut() {
//     localStorage.removeItem('user_id');
//     yield put(userSignOutSuccess(signOutUser));
// }

// Get request from action

// export function* createUserAccount() {
//     yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
// }

export function* signInUser() {
	yield takeEvery(SIGNIN_USER, signIn)
}

export function* changePassword() {
	yield takeEvery(CHANGE_PASSWORD, postChangePassword)
}

export function* forgotPassword() {
	yield takeEvery(FORGOT_PASSWORD, postForgotPassword)
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

// yield all

// export default function* rootSaga() {
//     yield all([fork(signInUser),
//         fork(createUserAccount),
//         fork(signOutUser)]);
// }

export default function* rootSaga() {
	yield all([
        fork(signInUser),
		fork(signOutUser), 
        fork(changePassword), 
        fork(forgotPassword)
    ])
}
