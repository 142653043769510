import Axios from 'axios';


export const issuingReportApi = (data) => {
    if(data.authToken != null){
        var mulai = 0;
        var baris = 10;
        
        if(data.page>0){
            mulai= data.pageSize + 1;
            baris=0;
            for(var i=0;i<=data.page;){
                baris = data.pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'report/issuing',
            params: {
                memberId : data.userId,
                merchantId : data.merchantId,
                memberName : data.memberName,
                startDate : data.startDate,
                endDate : data.endDate,
                offset: mulai,
                pageSize : data.pageSize,
                page : data.page,
                nRecords : data.pageSize
            },
            data: {},
            headers:{
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const redeemReportApi = (data) => {
    if(data.authToken != null){
        var mulai = 0;
        var baris = 10;
        
        if(data.page>0){
            mulai= data.pageSize + 1;
            baris=0;
            for(var i=0;i<=data.page;){
                baris = data.pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'report/redeem',
            params: {
                memberId : data.userId,
                merchantId : data.merchantId,
                memberName : data.memberName,
                startDate : data.startDate,
                endDate : data.endDate,
                offset: mulai,
                pageSize : data.pageSize,
                page : data.page,
                nRecords : data.pageSize
            },
            data: {},
            headers:{
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const memberBalanceApi = (data) => {
    if(data.authToken != null){
        var mulai = 0;
        var baris = 10;
        
        if(data.page>0){
            mulai= data.pageSize + 1;
            baris=0;
            for(var i=0;i<=data.page;){
                baris = data.pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'report/member-balance',
            params: {
                memberId : data.userId,
                merchantId : data.merchantId,
                memberName : data.memberName,
                startDate : data.startDate,
                endDate : data.endDate,
                offset: mulai,
                pageSize : data.pageSize,
                page : data.page,
                nRecords : data.pageSize
            },
            data: {},
            headers:{
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const voucherBalanceApi = (data) => {
    if(data.authToken != null){
        var mulai = 0;
        var baris = 10;
        
        if(data.page>0){
            mulai= data.pageSize + 1;
            baris=0;
            for(var i=0;i<=data.page;){
                baris = data.pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'report/voucher-balance',
            params: {
                memberId : data.userId,
                merchantId : data.merchantId,
                memberName : data.memberName,
                memberUsername : data.memberUsername,
                productName : data.productName,
                startDate : data.startDate,
                endDate : data.endDate,
                status : data.status,
                offset: mulai,
                pageSize : data.pageSize,
                page : data.page,
                nRecords : data.pageSize,
                isOwnProduct : data.isOwnProduct,
                statusList : data.statusList,
                voucherId : data.voucherId,
                voucherCode : data.voucherCode
            },
            data: {},
            headers:{
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const referralReportApi = (data) => {
    if(data.authToken != null){
        var mulai = 0;
        var baris = 10;
        
        if(data.page>0){
            mulai= data.pageSize + 1;
            baris=0;
            for(var i=0;i<=data.page;){
                baris = data.pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'report/referral-member',
            params: {
                memberId : data.userId,
                merchantId : data.merchantId,
                memberName : data.memberName,
                startDate : data.startDate,
                endDate : data.endDate,
                offset: mulai,
                pageSize : data.pageSize,
                page : data.page,
                nRecords : data.pageSize
            },
            data: {},
            headers:{
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const pointTransactionApi = (data) => {
    if(data.authToken != null){
        var mulai = 0;
        var baris = 10;
        
        if(data.page>0){
            mulai= data.pageSize + 1;
            baris=0;
            for(var i=0;i<=data.page;){
                baris = data.pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'report/point-transaction',
            params: {
                memberId : data.userId,
                merchantId : data.merchantId,
                trxType : data.trxType,
                memberName : data.memberName,
                startDate : data.startDate,
                endDate : data.endDate,
                offset: mulai,
                pageSize : data.pageSize,
                page : data.page,
                nRecords : data.pageSize
            },
            data: {},
            headers:{
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const pointTransferApi = (data) => {
    if(data.authToken != null){
        // var mulai = 0;
        // var baris = 20;
        
        // if(page>0){
        //     mulai= pageSize + 1;
        //     baris=0;
        //     for(var i=0;i<=page;){
        //         baris = pageSize + baris;
        //         i++;
        //     }
        //     mulai = baris - 20 + 1;
        // }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'point-transaction/point/transfer/history',
            params: {
                userId : data.userId,
                merchantId : data.merchantId,
                orderBy : '',
                orderType : 1,
                page : data.page,
                nRecords : data.pageSize,
                role : 1,
                startDate : data.startDate,
                endDate : data.endDate,
                username: data.username,
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const reportPartnerApi = (data) => {
    if (data.authToken != null) {
        var mulai = 0;
        var baris = 10;
        
        if(data.page>0){
            mulai= data.pageSize + 1;
            baris=0;
            for(var i=0;i<=data.page;){
                baris = data.pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + "report/report-partner",
            params: {
                memberId: data.userId,
                merchantId: data.merchantId,
                memberName: data.memberName,
                productName: data.productName,
                offset: mulai,
                pageSize: data.pageSize,
                page: data.page,
                nRecords: data.pageSize,
                merchantIdPartner: data.merchantIdPartner
            },
            data: {},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': data.authToken,
                'deviceUniqueId': data.deviceId
            }
        })
    }
}

export const redeemPartnerApi = ({authToken, deviceId, userId, merchantId, memberName, productName, status, page, pageSize, startDate, endDate, memberUsername}) => {
    if(authToken != null){
        var mulai = 0;
        var baris = 10;
        
        if(page>0){
            mulai= pageSize + 1;
            baris=0;
            for(var i=0;i<=page;){
                baris = pageSize + baris;
                i++;
            }
            mulai = baris - 10;
        }
        return Axios({
            method: 'get',
            url: window.ApiURL + 'report/report-redeemed-voucher-partner',
            params: {
                userId : userId,
                merchantId : merchantId,
                memberName : memberName,
                memberUsername : memberUsername,
                productName : productName,
                startDate : startDate,
                endDate : endDate,
                status : status || -1,
                offset: mulai,
                pageSize : pageSize,
                page : page,
                nRecords : pageSize
            },
            data: {},
            headers:{
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

