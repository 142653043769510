import Axios from 'axios';

export const viewCategoryApi = ({authToken, deviceId}) => {
    if(authToken != null){

        return Axios({
            method: 'get',
            url: window.ApiURL + 'category/view-category',
            params: {},
            data: {},
            headers:{
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};