import Axios from 'axios';


export const searchAdvertisingApi = ({authToken, deviceId, userId, merchantId, search, page, size}) => {
    if(authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'v2/advertising/search',
            params: {
                userId : userId,
                merchantId : merchantId,
                advertisingType : '',
                name : search,
                page : page,
                size : size,
                nRecords : 1000,
                existingDate : 0
            },
            data: {},
            headers:{
                'authToken': authToken,
                'deviceUniqueId' : deviceId,
            }
        });
    };
};

export const viewAdvertisingApi = ({authToken, deviceId, id, userId, merchantId}) => {
    if(authToken != null){

        return Axios({
            method: 'get',
            url: window.ApiURL + 'merchant/advertising/view',
            params: {
                advertisingId : id,
                userId : userId,
                merchantId : merchantId,
                memberSortBy : 1,
                memberSort : 2,
                memberPage : 0,
                memberNRecords : 99999,
                linkSortBy : 1,
                linkSort : 1,
                linkPage : 0,
                linkNRecords : 2
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};
export const createAdvertisingApi = ({authToken, deviceId, userId, merchantId, data}) => {
    if(authToken != null){

        return Axios({
            method: 'post',
            url: window.ApiURL + 'v2/advertising/create',
            data: {
                userId: userId,
                merchantId: merchantId,
                advertisingType : data.advertisingType,
                advertisingName : data.advertisingName,
                advertisingTier : data.advertisingTier,
                description : data.description,
                articleCategory : data.articleCategory,
                adsCategory : data.adsCategory,
                adsContent : data.adsContent,
                image : data.image,
                imagePortrait : data.imagePortrait,
                rewardId : data.rewardId,
                startDate : data.startDate,
                endDate : data.endDate,
                cityId  : data.cityId,
                advertisingPromotionType  : data.advertisingPromotionType,
                destinationUrl : data.destinationUrl,
                pushNotificationSendDate : data.pushNotificationSendDate,
                isAllMember : data.isAllMember,
                members : data.members,
                advertisings : data.advertisings,
                termAndCondition : data.termAndCondition
            },
            headers:{
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const updateAdvertisingApi = ({authToken, deviceId, userId, merchantId, id, data}) => {
    if(authToken != null){
        return Axios({
            method: 'put',
            url: window.ApiURL + 'v2/advertising/update',
            data: {
                userId: userId,
                merchantId: merchantId,
                advertisingType : data.advertisingType,
                advertisingId : id,
                advertisingName : data.advertisingName,
                articleCategory : data.articleCategory,
                advertisingTier : data.advertisingTier,
                description : data.description,
                adsCategory : data.adsCategory,
                adsContent : data.adsContent,
                image : data.image,
                imagePortrait : data.imagePortrait,
                rewardId : data.rewardId,
                startDate : data.startDate,
                endDate : data.endDate,
                cityId  : data.cityId,
                advertisingPromotionType  : data.advertisingPromotionType ,
                destinationUrl : data.destinationUrl,
                pushNotificationSendDate : data.pushNotificationSendDate,
                // isAllMember : data.isAllMember,
                members : data.members,
                advertisings : data.advertisings,
                termAndCondition : data.termAndCondition,
                adsCategoryName : data.adsCategoryName
            },
            headers:{
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

// export const deleteAdvertisingApi = ({authToken, deviceId, userId, merchantId, id}) => {
//     if(authToken != null){
//         return Axios({
//             method: 'post',
//             url: window.ApiURL + 'v2/advertising',
//             params: {
//                 userId : userId,
//                 merchantId : merchantId,
//                 advertisingId : id,
//             },
//             data:{},
//             headers:{
//                 'authToken': authToken,
//                 'deviceUniqueId' : deviceId,
//             }
//         });
//     };
// };

export const deleteAdvertisingApi = ({authToken, deviceId, userId, merchantId, id}) => {
    if(authToken != null){
        return Axios({
            method: 'POST',
            url: window.ApiURL + 'v2/advertising',
            params: {
                userId : userId,
                merchantId : merchantId,
                advertisingId: id,
            },
            headers:{
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const topDealsAdvertisingApi = (data) => {
    if(data.authToken != null){

        return Axios({
            method: 'put',
            url: window.ApiURL + data.api,
            data: {
                userId: data.userId,
                merchantId: data.merchantId,
                advertisingPromotionCode : data.advertisingPromotionCode,
                advertisingPromotionName : data.advertisingPromotionName,
                advertisingList : data.advertisingList
            },
            headers:{
                'Content-Type': 'application/json',
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const searchAdvertisingActiveApi = (data) => {
    if(data.authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'v2/advertising/promotion/search',
            params: {
                userId : data.userId,
                merchantId : data.merchantId,
                advertisingPromotionCode : data.advertisingPromotionCode
            },
            data: {},
            headers:{
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId,
            }
        });
    };
};