import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    VIEW_CATEGORY,
    VIEW_CATEGORY_SUCCESS,
    VIEW_CATEGORY_FAILED,
    BACK_TO_LOGIN
} from "constants/ActionTypes";
import { viewCategoryApi } from "../../appRedux/api/Category";

function* fetchViewCategory({payload}) {
    if(payload != null){
        try {
            let viewCategoryData = yield call(viewCategoryApi, payload);
            if (viewCategoryData.data) {
                yield put({type: VIEW_CATEGORY_SUCCESS, payload: viewCategoryData.data});
            } else {
                yield put({type: VIEW_CATEGORY_FAILED, payload: "Data is Empty"});
            }
        } catch (error) {
            if(error.response !== undefined) {
                if (error.response.data === null) {
                    yield put({
                        type: VIEW_CATEGORY_FAILED,
                        payload: "Sorry, this feature is not accessible at this time"
                    });
                } else if (error.response.data.abstractResponse.responseStatus === 'AUTH001') {
                    yield put({type: BACK_TO_LOGIN, payload: error.response.data.abstractResponse.responseMessage});
                } else {
                    yield put({
                        type: VIEW_CATEGORY_FAILED,
                        payload: "Data is Empty"
                    });
                }
            }
            else{
                yield put({
                    type: VIEW_CATEGORY_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

export function* viewCategory(){
    yield takeEvery(VIEW_CATEGORY, fetchViewCategory);
}

// yield all
export default function* rootSaga() {
    yield all([
        fork(viewCategory),
    ]);
}
